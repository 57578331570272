import {Component} from "react";

class NotFound extends Component {
    render() {
        return (
            <h1>NotFound</h1>
        );
    }
}

export default NotFound;